import React from 'react';
import {BillingAddressDropdown} from '../BillingAddressDropdown/BillingAddressDropdown';
import {ContactAndAddressSummary} from '../../../../ContactAndAddressSummary/ContactAndAddressSummary';
import {useBillingData} from '../WithBillingData/WithBillingData';
import {MemberAddressStepState} from '../../../../../../types/app.types';
import {MemberBillingAddressTitle} from '../BillingAddressTitle/MemberBillingAddressTitle';

export enum MemberBillingDetailsDataHook {
  root = 'MemberBillingDetails.root',
}

export const MemberBillingDetails = () => {
  const {currentBillingInfo, memberAddressStepState} = useBillingData();

  return (
    <>
      <MemberBillingAddressTitle />
      <div data-hook={MemberBillingDetailsDataHook.root}>
        {memberAddressStepState === MemberAddressStepState.OPEN && <BillingAddressDropdown />}
        {currentBillingInfo && (
          <ContactAndAddressSummary contact={currentBillingInfo?.contact} address={currentBillingInfo?.address} />
        )}
      </div>
    </>
  );
};
