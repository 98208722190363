import {Dropdown, DropdownOptionProps, DropdownTheme} from 'wix-ui-tpa';
import React from 'react';
import {useLocaleKeys} from '../../../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {useBillingData} from '../WithBillingData/WithBillingData';

export enum BillingAddressDropdownDataHook {
  root = 'BillingAddressDropdown.root',
  dropdown = 'BillingAddressDropdown.dropdown',
}

export const BillingAddressDropdown = () => {
  const {
    memberStore: {addressesInfo},
  } = useControllerProps();

  const localeKeys = useLocaleKeys();
  const {currentBillingInfo, onSetCurrentBillingInfoChange} = useBillingData();

  const dropdownOptions = addressesInfo?.addresses.map((address) => {
    return {
      id: address?.addressesServiceId,
      value: address.address?.streetAddress?.name
        ? `${address.address?.streetAddress?.name} ${
            address.address?.streetAddress?.number ?? /* istanbul ignore next */ ''
          }`
        : address.address?.addressLine,
      subtitle: `${address.contact?.firstName ?? /* istanbul ignore next */ ''} ${
        address.contact?.lastName ?? /* istanbul ignore next */ ''
      }`,
      isSelectable: true,
    };
  });
  const onSelect = (selectedOption: DropdownOptionProps) => {
    const selectedAddress = addressesInfo?.addresses?.find(
      (address) => address.addressesServiceId === selectedOption.id
    );
    selectedAddress && onSetCurrentBillingInfoChange(selectedAddress);
  };

  return (
    <div data-hook={BillingAddressDropdownDataHook.root}>
      <Dropdown
        data-hook={BillingAddressDropdownDataHook.dropdown}
        label={localeKeys.chooseAddressModal.billing_address_title()}
        initialSelectedId={currentBillingInfo?.addressesServiceId || addressesInfo.defaultAddressId}
        upgrade={true}
        theme={DropdownTheme.Box}
        options={dropdownOptions}
        onChange={(option) => onSelect(option)}
      />
    </div>
  );
};
