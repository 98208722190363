import React, {ComponentType, createContext, useContext, useEffect, useRef, useState} from 'react';
import {FormHandle, FormValues} from '@wix/form-viewer';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {FormViewerHandle, FormError} from '@wix/form-viewer/widget';
import {FullAddressContactDetails, ApiAddress, CommonVatId} from '@wix/ambassador-ecom-v1-checkout/types';
import {
  getContactDetailsFromContactFormValues,
  getContactFormCurrentStateWithUpdatedCountry,
  getContactFormInitialState,
} from '../../../../Form/ContactForm/contactForm.utils';
import {CashierMandatoryFieldsOverrides, MemberAddressStepState} from '../../../../../../types/app.types';
import {
  getAddressFormInitialState,
  getAddressFromAddressFormValues,
} from '../../../../Form/AddressForm/addressForm.utils';
import {getVatFormInitialState, getVatFromVatFormValues} from '../../../../Form/VatIdForm/VatForm.utils';
import {AddressWithContactModel} from '../../../../../../domain/models/AddressWithContact.model';

export type BillingDataContextType = {
  setContactFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
  contactFormValues: FormValues;
  contactFormViewer: React.RefObject<FormHandle>;
  setContactFormErrors: React.Dispatch<React.SetStateAction<FormError[]>>;
  contactFormErrors: FormError[];
  setAddressFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
  addressFormValues: FormValues;
  addressFormViewer: React.RefObject<FormHandle>;
  setAddressFormErrors: React.Dispatch<React.SetStateAction<FormError[]>>;
  addressFormErrors: FormError[];
  isFormValid: () => Promise<boolean>;
  initForm: () => void;
  updateContactCountry: (country: string) => void;
  getContactDetailsFormValues: () => FullAddressContactDetails;
  getAddressFormValues: () => ApiAddress;
  cashierMandatoryFields: CashierMandatoryFieldsOverrides;
  setCashierMandatoryFields: React.Dispatch<React.SetStateAction<CashierMandatoryFieldsOverrides>>;
  vatFormViewer: React.RefObject<FormHandle>;
  vatFormValues: FormValues;
  setVatFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
  getVatFormValues: () => CommonVatId | undefined;
  setVatFormErrors: React.Dispatch<React.SetStateAction<FormError[]>>;
  vatFormErrors: FormError[];
  setBillingSameAsShipping: React.Dispatch<React.SetStateAction<boolean>>;
  billingSameAsShipping: boolean;
  currentBillingInfo?: AddressWithContactModel;
  onSetCurrentBillingInfoChange: (billingInfo: AddressWithContactModel) => void;
  memberAddressStepState: MemberAddressStepState;
  setMemberAddressStepState: (state: MemberAddressStepState) => void;
  resetMemberAddressState: () => void;
};

export const BillingDataContext = createContext({} as BillingDataContextType);

export function withBillingData<T extends object>(Component: ComponentType<T>) {
  return function Wrapper(props: T) {
    const {
      checkoutStore: {checkout},
      checkoutSettingsStore: {checkoutSettings},
      memberStore: {defaultAddress},
    } = useControllerProps();

    const [contactFormValues, setContactFormValues] = useState<FormValues>(
      getContactFormInitialState({
        checkoutSettings,
        contact: checkout.billingInfo?.contact,
        country: checkout.billingInfo?.address?.country,
      })
    );
    const [vatFormValues, setVatFormValues] = useState<FormValues>(
      getVatFormInitialState(checkout.billingInfo?.contact)
    );
    const [addressFormValues, setAddressFormValues] = useState<FormValues>(
      getAddressFormInitialState(checkoutSettings, checkout.billingInfo?.address)
    );

    const [cashierMandatoryFields, setCashierMandatoryFields] = useState<CashierMandatoryFieldsOverrides>({});

    const contactFormViewer = useRef<FormViewerHandle>(null);
    const vatFormViewer = useRef<FormViewerHandle>(null);
    const addressFormViewer = useRef<FormViewerHandle>(null);

    const formRefs = [contactFormViewer, vatFormViewer, addressFormViewer];

    const [contactFormErrors, setContactFormErrors] = useState<FormError[]>([]);
    const [addressFormErrors, setAddressFormErrors] = useState<FormError[]>([]);
    const [vatFormErrors, setVatFormErrors] = useState<FormError[]>([]);

    const isFormValid = async () => {
      const areFormsValidArr = await Promise.all(
        formRefs.filter((ref) => !!ref.current).map((ref) => ref.current!.validate())
      );

      return !areFormsValidArr.includes(false);
    };

    const initForm = () => {
      setContactFormValues(
        getContactFormInitialState({
          checkoutSettings,
          contact: checkout.billingInfo?.contact,
          country: checkout.billingInfo?.address?.country,
        })
      );
      setVatFormValues(getVatFormInitialState(checkout.billingInfo?.contact));
      setAddressFormValues(getAddressFormInitialState(checkoutSettings, checkout.billingInfo?.address));
    };

    const updateContactCountry = (country: string) => {
      setContactFormValues(
        getContactFormCurrentStateWithUpdatedCountry({
          contactFormValues,
          country,
        })
      );
    };

    const getContactDetailsFormValues = () => {
      return getContactDetailsFromContactFormValues(contactFormValues, checkoutSettings);
    };

    const getAddressFormValues = () => {
      return getAddressFromAddressFormValues(checkoutSettings, addressFormValues);
    };

    const getVatFormValues = () => {
      return getVatFromVatFormValues(vatFormValues);
    };

    const [billingSameAsShipping, setBillingSameAsShipping] = useState<boolean>(true);
    const [currentBillingInfo, setCurrentBillingInfo] = useState(checkout.billingInfo ?? undefined);

    useEffect(() => {
      if (!currentBillingInfo && defaultAddress) {
        setCurrentBillingInfo(defaultAddress);
      }
    }, [currentBillingInfo, defaultAddress]);

    const onSetCurrentBillingInfoChange = (billingInfo: AddressWithContactModel): void => {
      setCurrentBillingInfo(billingInfo);
    };

    const [memberAddressStepState, setMemberAddressStepState] = useState(MemberAddressStepState.COLLAPSED);

    const resetMemberAddressState = () => {
      setMemberAddressStepState(MemberAddressStepState.COLLAPSED);
    };
    return (
      <BillingDataContext.Provider
        value={{
          contactFormValues,
          setContactFormValues,
          contactFormViewer,
          setContactFormErrors,
          contactFormErrors,
          isFormValid,
          initForm,
          updateContactCountry,
          getContactDetailsFormValues,
          cashierMandatoryFields,
          setCashierMandatoryFields,
          vatFormViewer,
          vatFormValues,
          setVatFormErrors,
          vatFormErrors,
          setVatFormValues,
          getVatFormValues,
          addressFormViewer,
          addressFormValues,
          setAddressFormValues,
          setAddressFormErrors,
          addressFormErrors,
          getAddressFormValues,
          setBillingSameAsShipping,
          billingSameAsShipping,
          currentBillingInfo,
          onSetCurrentBillingInfoChange,
          memberAddressStepState,
          setMemberAddressStepState,
          resetMemberAddressState,
        }}>
        <Component {...props} />
      </BillingDataContext.Provider>
    );
  };
}

export function useBillingData() {
  const {
    contactFormValues,
    setContactFormValues,
    contactFormViewer,
    setContactFormErrors,
    contactFormErrors,
    isFormValid,
    initForm,
    updateContactCountry,
    getContactDetailsFormValues,
    cashierMandatoryFields,
    setCashierMandatoryFields,
    vatFormViewer,
    vatFormValues,
    setVatFormErrors,
    vatFormErrors,
    setVatFormValues,
    getVatFormValues,
    addressFormViewer,
    addressFormValues,
    setAddressFormValues,
    setAddressFormErrors,
    addressFormErrors,
    getAddressFormValues,
    setBillingSameAsShipping,
    billingSameAsShipping,
    currentBillingInfo,
    onSetCurrentBillingInfoChange,
    memberAddressStepState,
    setMemberAddressStepState,
    resetMemberAddressState,
  } = useContext(BillingDataContext);

  return {
    contactFormValues,
    setContactFormValues,
    contactFormViewer,
    setContactFormErrors,
    contactFormErrors,
    isFormValid,
    initForm,
    updateContactCountry,
    getContactDetailsFormValues,
    cashierMandatoryFields,
    setCashierMandatoryFields,
    vatFormViewer,
    vatFormValues,
    setVatFormErrors,
    vatFormErrors,
    setVatFormValues,
    getVatFormValues,
    addressFormViewer,
    addressFormValues,
    setAddressFormValues,
    setAddressFormErrors,
    addressFormErrors,
    getAddressFormValues,
    setBillingSameAsShipping,
    billingSameAsShipping,
    currentBillingInfo,
    onSetCurrentBillingInfoChange,
    memberAddressStepState,
    setMemberAddressStepState,
    resetMemberAddressState,
  };
}
